import { Editor, Node, type EditorOptions } from "@tiptap/vue-3";
import { Text } from "@tiptap/extension-text";
import { Paragraph } from "@tiptap/extension-paragraph";
import { Bold } from "@tiptap/extension-bold";
import { Link } from "@tiptap/extension-link";
import { Italic } from "@tiptap/extension-italic";
import suggestInputs from "./suggestions/suggestInputs";
import { Reference } from "./references";
import type { FormKitNode } from "@formkit/core";

const OneLiner = Node.create({
  name: "oneLiner",
  topNode: true,
  content: "block",
});

export function singleLineEditor(
  element: HTMLElement,
  node: FormKitNode,
  options: Partial<EditorOptions> = {}
) {
  const editor = new Editor({
    element,

    extensions: [
      OneLiner,
      Text,
      Paragraph,
      Bold,
      Italic,
      Link.configure({
        openOnClick: false,
        autolink: true,
        defaultProtocol: "https",
      }),
      Reference.configure({
        HTMLAttributes: {
          class:
            "bg-fuchsia-100 text-fuchsia-800 px-1 py-0 inline-block text-xs rounded-md",
        },
        formkitNode: node,
        suggestion: suggestInputs(node),
      }),
    ],
    ...options,
  });
  return editor;
}

export function noReferenceSingleLineEditor(
  element: HTMLElement,
  options: Partial<EditorOptions> = {}
) {
  const editor = new Editor({
    element,
    extensions: [
      OneLiner,
      Text,
      Paragraph,
      Bold,
      Italic,
      Link.configure({
        openOnClick: false,
        autolink: true,
        defaultProtocol: "https",
      }),
    ],
    ...options,
  });
  return editor;
}
