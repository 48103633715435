import { items, openReferenceDialog } from "../openReferenceDialog";
import type { FormKitNode } from "@formkit/core";
import type { Reference } from "~/types";

export default (node: FormKitNode) => ({
  items: items.bind(null, node),
  render() {
    let dialogInstance: ReturnType<typeof openReferenceDialog> | null = null;

    return {
      onStart(props: Record<string, any>) {
        const { editor, clientRect } = props;

        // Retrieve the items
        const suggestionItems = items(node, { query: props.query });

        // Define the command function
        const command = (item: Reference) => {
          props.command(item);
        };

        dialogInstance = openReferenceDialog({
          editor,
          view: editor.view,
          selection: editor.view.state.selection,
          storage: editor.storage,
          items: suggestionItems,
          command,
          clientRect,
        });
      },

      onUpdate(props: Record<string, any>) {
        if (!dialogInstance) {
          return;
        }

        // Update the items and command if necessary
        const suggestionItems = items(node, { query: props.query });
        dialogInstance.updateProps({
          items: suggestionItems,
        });

        if (!props.clientRect || !dialogInstance.popup) {
          return;
        }

        dialogInstance.popup.setProps({
          getReferenceClientRect: props.clientRect,
        });
      },

      onKeyDown(props: { event: KeyboardEvent }) {
        if (props.event.key === "Escape" && dialogInstance?.popup) {
          dialogInstance.popup.hide();
          return true;
        }

        return dialogInstance?.component.ref?.onKeyDown(props) || false;
      },

      onExit() {
        dialogInstance?.destroy();
        dialogInstance = null;
      },
    };
  },
});
