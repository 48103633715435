import type { FormKitNode } from "@formkit/core";
import { mergeAttributes, Node, type Editor } from "@tiptap/core";
import {
  type DOMOutputSpec,
  type Node as ProseMirrorNode,
} from "@tiptap/pm/model";
import { PluginKey } from "@tiptap/pm/state";
import { Suggestion, type SuggestionOptions } from "./suggestion";
import { NodeSelection } from "@tiptap/pm/state";
import { VueRenderer } from "@tiptap/vue-3";
import tippy from "tippy.js";
import ReferenceDialog from "~/components/editor/ReferenceDialog.vue";
import { Plugin } from "prosemirror-state";
import type { EditorView } from "@tiptap/pm/view";
import type { Selection } from "@tiptap/pm/state";
import { items, openReferenceDialog } from "./openReferenceDialog";

export interface ReferenceNodeAttrs {
  id: string | null;
  label?: string | null;
}

export type ReferenceOptions<
  SuggestionItem = any,
  Attrs extends Record<string, any> = ReferenceNodeAttrs
> = {
  formkitNode?: FormKitNode;
  HTMLAttributes: Record<string, any>;
  renderText: (props: {
    options: ReferenceOptions<SuggestionItem, Attrs>;
    node: ProseMirrorNode;
  }) => string;
  renderHTML: (props: {
    options: ReferenceOptions<SuggestionItem, Attrs>;
    node: ProseMirrorNode;
  }) => DOMOutputSpec;
  deleteTriggerWithBackspace: boolean;
  suggestion: Omit<SuggestionOptions<SuggestionItem, Attrs>, "editor">;
};

export const ReferencePluginKey = new PluginKey("reference");
export type ReferenceStorage = { receipts: string[]; referenceDialog: any };

export const Reference = Node.create<ReferenceOptions, ReferenceStorage>({
  name: "reference",
  addStorage() {
    return {
      receipts: [],
      referenceDialog: null,
    };
  },
  addOptions() {
    return {
      HTMLAttributes: {},
      renderText({ options, node }) {
        return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`;
      },
      deleteTriggerWithBackspace: false,
      renderHTML({ options, node }) {
        return [
          "span",
          mergeAttributes(this.HTMLAttributes, options.HTMLAttributes),
          `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`,
        ];
      },
      suggestion: {
        char: "@",
        pluginKey: ReferencePluginKey,
        command: ({ editor, range, props }) => {
          const nodeAfter = editor.view.state.selection.$to.nodeAfter;
          const overrideSpace = nodeAfter?.text?.startsWith(" ");

          if (overrideSpace) {
            range.to += 1;
          }

          // Replace the content at the range with the new reference node
          editor
            .chain()
            .focus()
            .insertContentAt(range, [
              {
                type: this.name,
                attrs: props,
              },
              {
                type: "text",
                text: " ",
              },
            ])
            .run();

          // Move the cursor to the end of the inserted content
          editor.view.dom.ownerDocument.defaultView
            ?.getSelection()
            ?.collapseToEnd();
        },
        allow: ({ state, range }) => {
          const $from = state.doc.resolve(range.from);
          const type = state.schema.nodes[this.name];
          const allow = !!$from.parent.type.contentMatch.matchType(type);
          return allow;
        },
      },
    };
  },

  group: "inline",

  inline: true,

  selectable: true,

  atom: true,

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-id"),
        renderHTML: (attributes) => {
          if (!attributes.id) {
            return {};
          }

          return {
            "data-id": attributes.id,
          };
        },
      },

      label: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-label"),
        renderHTML: (attributes) => {
          if (!attributes.label) {
            return {};
          }

          return {
            "data-label": attributes.label,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `span[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    const mergedOptions = { ...this.options };
    mergedOptions.HTMLAttributes = mergeAttributes(
      {
        "data-type": this.name,
        class: "reference-node", // Add this class for easier selection
      },
      this.options.HTMLAttributes,
      HTMLAttributes
    );
    const html = this.options.renderHTML({
      options: mergedOptions,
      node,
    });

    if (typeof html === "string") {
      return ["span", mergedOptions.HTMLAttributes, html];
    }
    return html;
  },

  renderText({ node }) {
    return this.options.renderText({
      options: this.options,
      node,
    });
  },

  onCreate() {
    this.editor.state.doc.descendants(watchForDependencies.bind(this as any));
  },
  onUpdate() {
    this.editor.state.doc.descendants(watchForDependencies.bind(this as any));
  },

  onDestroy() {
    closeReferenceDialog(this.storage);
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ];
  },
});

function watchForDependencies(
  this: typeof Reference & { editor: Editor },
  node: ProseMirrorNode
) {
  if (!this.options.formkitNode) return;
  // Remove any previous listeners
  if (Array.isArray(this.storage?.receipts)) {
    this.storage?.receipts.forEach((receipt: string) =>
      this.options.formkitNode?.off(receipt)
    );
    this.storage.receipts = [];
  }
  if (node.type.name === this.name) {
    // Get all the reference nodes, find their related nodes using this.options.formkitNode.at(nodeName)
    // and then add an event listener for the prop:label event on those remote nodes. If they change, then
    // update the content of the reference node with the new label.
    if (node.attrs.id && node.attrs.id.startsWith("node:")) {
      const nodeName = node.attrs.id.split(":")[1];
      const dependencyNode = this.options.formkitNode.at(nodeName);
      if (dependencyNode) {
        this.storage.receipts.push(
          dependencyNode.on("prop:label", ({ payload }) => {
            const { state, view } = this.editor;
            const { tr } = state;
            let pos = null;
            state.doc.descendants((n, pos_) => {
              if (n === node) {
                pos = pos_;
                return false; // Stop traversal when node is found
              }
            });
            if (!pos) return;
            // Update the node's attributes
            tr.setNodeAttribute(pos, "label", payload);

            // Dispatch the transaction to update the editor's state
            view.dispatch(tr);
          })
        );
      }
    }
  }
}

// function openReferenceDialog({
//   editor,
//   node,
//   view,
//   selection,
//   query,
//   storage,
// }: {
//   editor: Editor;
//   node: ProseMirrorNode | null;
//   view: EditorView;
//   selection: Selection;
//   query: string;
//   storage: { referenceDialog: any };
// }) {
//   if (typeof storage?.referenceDialog?.popup?.setProps === "function") {
//     // Update existing dialog
//     storage.referenceDialog.component.updateProps({
//       editor,
//       node,
//       query,
//     });
//     console.log(storage.referenceDialog);
//     // Reposition the popup
//     storage.referenceDialog.popup.setProps({
//       getReferenceClientRect: () => {
//         const coords = view.coordsAtPos(selection.from);
//         return {
//           width: 0,
//           height: 0,
//           top: coords.top,
//           bottom: coords.bottom,
//           left: coords.left,
//           right: coords.right,
//         };
//       },
//     });

//     return;
//   }

//   // Create new dialog
//   const component = new VueRenderer(ReferenceDialog, {
//     props: { editor, node, query },
//     editor,
//   });

//   const popup = tippy("body", {
//     getReferenceClientRect: () => {
//       const coords = view.coordsAtPos(selection.from);
//       return {
//         width: 0,
//         height: 0,
//         top: coords.top,
//         bottom: coords.bottom,
//         left: coords.left,
//         right: coords.right,
//       };
//     },
//     appendTo: () => document.body,
//     content: component.element,
//     showOnCreate: true,
//     interactive: true,
//     trigger: "manual",
//     placement: "bottom-start",
//   });

//   storage.referenceDialog = { component, popup };
// }

function closeReferenceDialog(storage: { referenceDialog: any }) {
  if (storage.referenceDialog) {
    storage.referenceDialog.popup.destroy();
    storage.referenceDialog.component.destroy();
    storage.referenceDialog = null;
  }
}
