import {
  isDOM,
  type FormKitNode,
  type FormKitPseudoProp,
  type FormKitPseudoProps,
  type FormKitSectionsSchema,
} from "@formkit/core";
import {
  eachSection,
  findSection,
  type FormKitOptionsItem,
} from "@formkit/inputs";
import { cloneAny, whenAvailable } from "@formkit/utils";
import { Editor } from "@tiptap/core";
import {
  noReferenceSingleLineEditor,
  singleLineEditor,
} from "./tiptap/editors";
import { dragAndDrop } from "@/utils/drag-and-drop/vue";
import { insert } from "@/utils/drag-and-drop";
import { sentence } from "@formkit/i18n";
import type { FormStore } from "~/types";

const innerClicks = new WeakSet<Event>();

let activeNode: FormKitNode | null = null;

if (typeof window !== "undefined") {
  window.addEventListener("click", (e) => {
    if (innerClicks.has(e)) {
      // Don't worry about this one
      return;
    }
    // This is an "outside" click, so close the active node
    if (activeNode) {
      activeNode.props.isFocused = null;
      activeNode = null;
    }
  });

  window.addEventListener("focusin", (e) => {
    if (
      activeNode &&
      !document
        .querySelector("[data-is-focused]")
        ?.contains(e.target as Element)
    ) {
      // Focus moved outside the active node
      activeNode.props.isFocused = null;
      activeNode = null;
    }
  });

  // Optional: handle blur events as well
  window.addEventListener("focusout", (e) => {
    if (activeNode && !e.relatedTarget) {
      // Focus moved out of the document
      activeNode.props.isFocused = null;
      activeNode = null;
    }
  });
}

/**
 * Focus node for FormKit
 * @param event - The mouse event
 */
function focusNode(node: FormKitNode, event: MouseEvent) {
  innerClicks.add(event);
  if (!node.props.isFocused) {
    if (activeNode) {
      activeNode.props.isFocused = null;
    }
    activeNode = node;
    node.props.isFocused = true;
  }
}

/**
 * Edit mode for FormKit
 * @param node - The FormKit node to enable edit mode for
 */
export function editMode(node: FormKitNode) {
  node.addProps({
    idx: {
      default: "",
    },
    focusNode: {
      default: focusNode.bind(null, node),
    },
    isFocused: {
      default: null,
    },
    __loading: {
      default: false,
    },
    isMounted: {
      default: function isMounted(option: FormKitOptionsItem): boolean {
        if (!option || typeof option !== "object") return false;
        const propKey = `__option${option.attrs?.id}EditorMounted`;
        return !!(propKey in node.props && node.props[propKey]);
      },
    },
    __pageId: {
      default: "",
    },
    __pageSchema: {
      default: {},
    },
  });

  /**
   * Defines the relationship between section names, applicable input types, and the variable to be used in that section.
   * - Key: The section name
   * - Value: An object where:
   *   - Key: The input type ('*' means all input types)
   *   - Value: The variable name to be used in that section
   */
  const editableSections: Record<string, Record<string, string | false>> = {
    label: {
      radio: false,
      checkboxmultiple: false,
      "*": "label", // use the $label variable
    },
    help: {
      "*": "help", // use the $help variable
    },
    legend: {
      "*": "label", // use the $label variable
    },
  };

  const inputType = node.props.type;
  const family = node.props.family;
  const isMultiBox = inputType === "checkboxmultiple" || inputType === "radio";

  if (node.props.__pageId && node.props.type === "repeater") {
    node.props.min = node.props.max = 1;
  }

  for (const sectionName of Object.keys(editableSections)) {
    const section = editableSections[sectionName];
    const variableName = section[node.props.type] || section["*"];
    if (variableName) {
      node.addProps({
        [`${sectionName}Id`]: {
          default: useId(),
        },
        [`formatted${sentence(variableName)}`]: {
          default: "",
        },
        [`__${sectionName}EditorMounted`]: {
          default: false,
        },
      });
    }
  }

  node.props.labelClass += " min-w-5 min-h-4";

  const inputDefinition = cloneAny(node.props.definition);

  function updateSchemaKey(property: string, value: any) {
    node
      .at("$root")
      ?.emit("updateSchemaKey", { property, value, idx: node.props.idx });
  }

  if (inputDefinition && typeof inputDefinition.schema === "function") {
    const originalSchema = inputDefinition.schema;

    const higherOrderSchema = (extensions: FormKitSectionsSchema) => {
      const inputSchema = originalSchema(extensions);
      const fullSchema = Array.isArray(inputSchema)
        ? inputSchema[0]
        : inputSchema;

      eachSection(fullSchema, (section) => {
        if (!section.meta) return;
        if (!isDOM(section)) return;
        const sectionName = section.meta.section;
        if (typeof sectionName !== "string") return;
        const variableName =
          sectionName in editableSections
            ? editableSections[sectionName][inputType] ||
              editableSections[sectionName]["*"]
            : false;

        /**
         * Handle the "normal" editable sections in the editableSections map above:
         */
        if (sectionName in editableSections && variableName) {
          if (section.if) {
            section.if = section.if.replaceAll(`$${variableName}`, "true");
            if (!section.if.startsWith("$:")) {
              section.if = `$: ${section.if}`;
            }
            section.attrs = {
              ...(section.attrs || {}),
              id: `$${sectionName}Id`,
            };
          } else {
            section.if = `$: true`;
          }
          section.children = [
            {
              $el: "span",
              attrs: {
                class: `$__${sectionName}EditorMounted && 'sr-only'`,
                id: `$${sectionName}Id + '_source'`,
                innerHTML: `$formatted${sentence(
                  variableName
                )} || $${variableName}`,
              },
            },
            {
              $el: "span",
              attrs: {
                id: `$${sectionName}Id + '_editor'`,
              },
            },
          ];
        }

        if (family === "box") {
          switch (sectionName) {
            case "decorator":
              section.$el = "label";
              if (isMultiBox) {
                section.attrs = section.attrs
                  ? { ...section.attrs, for: "$option.attrs.id" }
                  : { for: "$option.attrs.id" };
              } else {
                section.attrs = section.attrs
                  ? { ...section.attrs, for: "$id" }
                  : { for: "$id" };
              }
              break;
            case "wrapper":
              section.$el = "span";
              break;
            case "input":
              if (!section.attrs || "if" in section.attrs) break;
              section.attrs.type = inputType === "radio" ? "radio" : "checkbox";
              break;
            case "label":
              if (inputType === "checkboxsingle") break;
              const id = "$option.attrs.id + '_label'";
              if (!section.attrs) {
                section.attrs = { id };
              } else {
                // @ts-ignore-next-line almost for sure this is ok:
                section.attrs.id = id;
                // Make the option not render a value:
                section.children = [
                  {
                    $el: "span",
                    attrs: {
                      id: '$option.attrs.id + "_source"',
                      innerHTML: "$option.formattedLabel || $option.label",
                      class:
                        "$__loading !== true && $isMounted($option) && 'sr-only'",
                    },
                  },
                  {
                    $el: "span",
                    attrs: { id: '$option.attrs.id + "_editor"' },
                  },
                ];
              }
              break;
          }
        }
      });

      const [_p3, outerCondition] = findSection(fullSchema, "outer");

      if (outerCondition && outerCondition.else && isDOM(outerCondition.else)) {
        const outer = outerCondition.else;
        if (outer.attrs && typeof outer.attrs === "object") {
          // inject drag handle container
          if (outer.children && Array.isArray(outer.children)) {
            outer.children = [
              {
                $cmp: "ElementEditorWrapper",
                children: outer.children,
                props: {
                  idx: "$idx",
                },
              },
            ];
          }
        }
      }

      return inputSchema;
    };

    inputDefinition.schema = higherOrderSchema;

    if (inputDefinition.schemaMemoKey) {
      if (
        node.props.type === "checkbox" ||
        node.props.type === "checkboxmultiple" ||
        node.props.type === "checkboxsingle" ||
        node.props.type === "radio"
      ) {
        inputDefinition.schemaMemoKey += `_${node.props.type}_editMode`;
      } else {
        inputDefinition.schemaMemoKey += "_editMode";
      }
    } else {
      inputDefinition.schemaMemoKey = `${node.props.type}_editMode`;
    }
    node.props.definition = inputDefinition;
  }

  /**
   * Initialize the editor for each of the "editable" sections:
   */
  for (const sectionName in editableSections) {
    const variableName =
      editableSections[sectionName][node.props.type] ||
      editableSections[sectionName]["*"];
    if (!variableName) continue;
    let sectionEditor: Editor | null = null;
    const id = node.props[`${sectionName}Id`];
    whenAllAvailable(
      [`${id}`, `${id}_editor`, `${id}_source`],
      (wrapper, editor, source) => {
        function bootEditor() {
          if (!variableName) return;
          wrapper.removeAttribute("for");
          const content = source.innerHTML;
          // Create the section editor:
          sectionEditor = singleLineEditor(editor as HTMLElement, node, {
            content,
            onUpdate: () => {
              updateSchemaKey(variableName, sectionEditor?.getHTML());
            },
            onFocus: () => {
              node.emit("editorFocus", sectionEditor, true);
            },
          });
          node.props[`__${sectionName}EditorMounted`] = true;
        }
        if (!node.props.__loading) {
          bootEditor();
        }
        node.on("prop:__loading", ({ payload: isLoading }) => {
          if (!isLoading) {
            bootEditor();
          } else {
            sectionEditor?.destroy();
            sectionEditor = null;
            node.props[`__${sectionName}EditorMounted`] = false;
          }
        });
      }
    );

    node.on("destroy", () => {
      if (sectionEditor) {
        sectionEditor.destroy();
        sectionEditor = null;
      }
      activeNode = null;
    });
  }

  // Initialize the editor for each of the option labels:
  const optionEditors = new Set();
  function bootOptionEditor(option: FormKitOptionsItem) {
    if (typeof option !== "object") return;
    if (optionEditors.has(option.attrs?.id)) return;
    optionEditors.add(option.attrs?.id);

    let optionEditor: null | Editor = null;
    if (option.attrs?.id) {
      whenAllAvailable(
        [`${option.attrs.id}_editor`, `${option.attrs.id}_source`],
        (editor, source) => {
          function bootEditor() {
            const content = source.innerHTML;
            const propKey = `__option${option.attrs?.id}EditorMounted`;
            optionEditor = noReferenceSingleLineEditor(editor as HTMLElement, {
              content,
              onUpdate: () => {
                const optionIndex = node.props.options.findIndex(
                  (opt: FormKitOptionsItem) =>
                    opt.attrs?.id === option.attrs?.id
                );
                if (optionIndex !== -1) {
                  node.props.options[optionIndex].formattedLabel =
                    optionEditor?.getHTML() ?? "";
                  node.props.options[optionIndex].label =
                    optionEditor?.getText() ?? "";
                }
                updateSchemaKey("options", node.props.options);
              },
            });
            node.props[propKey] = true;
          }

          if (!node.props.__loading) {
            bootEditor();
          }
          node.on("prop:__loading", ({ payload: isLoading }) => {
            if (!isLoading) {
              bootEditor();
            } else {
              optionEditor?.destroy();
              optionEditor = null;
              node.props[`__option${option.attrs?.id}EditorMounted`] = false;
            }
          });
        }
      );
      node.on("destroy", () => {
        if (optionEditor) {
          optionEditor.destroy();
          optionEditor = null;
        }
      });
    }
  }

  /**
   * Initialize the editor for each of the option labels:
   */
  if (isMultiBox) {
    if (Array.isArray(node.props.options)) {
      node.props.options.forEach(bootOptionEditor);
    }
    node.on("prop:options", ({ payload: options }) => {
      if (Array.isArray(options)) {
        options.forEach(bootOptionEditor);
      }
    });
    node.hook.prop((payload, next) => {
      if (payload.prop === "options") {
        const normalizedPayload = next(payload);
        normalizedPayload.value.forEach((option: FormKitOptionsItem) => {
          const propKey = `__option${option.attrs?.id}EditorMounted`;
          if (!(propKey in node.props)) {
            node.addProps({
              [propKey]: { default: false },
            });
          }
          return option;
        });
        return normalizedPayload;
      }
      return next(payload);
    });
  }
}

const timeouts = new WeakMap<FormKitNode, NodeJS.Timeout>();
