import type { FormKitOptionsGroupItem } from "@formkit/inputs";

export type FormKitGroupWithColor = FormKitOptionsGroupItem & {
  color: string;
  grouping: string;
};
type FormKitOptionsListWithGroupsAndColor = FormKitGroupWithColor[];

export const availableInputs: FormKitOptionsListWithGroupsAndColor = [
  {
    group: "Page content",
    color: "gray",
    grouping: "page",
    options: [
      {
        label: "Heading",
        value: "heading",
        attrs: {
          help: "Large text heading.",
        },
      },
      {
        label: "Paragraph",
        value: "paragraph",
        attrs: {
          help: "Adds a paragraph of text to the page.",
        },
      },
    ],
  },
  {
    group: "Common Inputs",
    color: "green",
    grouping: "common",
    options: [
      {
        label: "Text",
        value: "text",
        attrs: {
          help: "Single line text input.",
        },
      },
      {
        label: "Textarea",
        value: "textarea",
        attrs: {
          help: "Multi-line text input.",
        },
      },
      {
        label: "Single Checkbox",
        value: "checkboxsingle",
        attrs: {
          help: "Single checkbox input.",
        },
      },
    ],
  },
  {
    group: "Specialized Text Inputs",
    color: "blue",
    grouping: "specialized",
    options: [
      {
        label: "Currency",
        value: "currency",
        attrs: {
          help: "Number formatted to locale and currency.",
        },
      },
      {
        label: "Number",
        value: "number",
        attrs: {
          help: "Number with optional min, max, and step.",
        },
      },
      {
        label: "Telephone",
        value: "tel",
        attrs: {
          help: "Text with input optimized for a phone number.",
        },
      },
    ],
  },
  {
    group: "Date & Time Inputs",
    color: "purple",
    grouping: "datetime",
    options: [
      {
        label: "Datepicker",
        value: "datepicker",
        attrs: {
          help: "Date selection input with calendar and timezone support.",
        },
      },
    ],
  },
  {
    group: "Option Inputs",
    color: "orange",
    grouping: "options",
    options: [
      {
        label: "Autocomplete",
        value: "autocomplete",
        attrs: {
          help: "Input with search that allows single or multiple selections.",
        },
      },
      {
        label: "Multiple Checkboxes",
        value: "checkboxmultiple",
        attrs: {
          help: "Multiple checkbox input.",
        },
      },
      {
        label: "Dropdown",
        value: "dropdown",
        attrs: {
          help: "Input with single or multiple selections from a list of options",
        },
      },
      {
        label: "Radio",
        value: "radio",
        attrs: {
          help: "Single selection input from a set of options.",
        },
      },
      {
        label: "Taglist",
        value: "taglist",
        attrs: {
          help: "Input allowing multiple selections from a list of options.",
        },
      },
    ],
  },
  {
    group: "Range Inputs",
    color: "red",
    grouping: "range",
    options: [
      {
        label: "Rating",
        value: "rating",
        attrs: {
          help: "Rating input with customizable min and max value and step.",
        },
      },
      {
        label: "Slider",
        value: "slider",
        attrs: {
          help: "Slider input with support for up to two handles and customizable min, max, and step.",
        },
      },
    ],
  },
  {
    group: "Color Inputs",
    color: "teal",
    grouping: "color",
    options: [
      {
        label: "Colorpicker",
        value: "colorpicker",
        attrs: {
          help: "Color picker input with support for multiple color formats, alpha transparency, and swatches.",
        },
      },
    ],
  },
  {
    group: "File Inputs",
    color: "yellow",
    grouping: "file",
    options: [
      {
        label: "File",
        value: "file",
        attrs: {
          help: "File upload input with support for single or multiple files.",
        },
      },
    ],
  },
  {
    group: "Control Inputs",
    color: "indigo",
    grouping: "control",
    options: [
      {
        label: "Repeater",
        value: "repeater",
        attrs: {
          help: "Input that allows for repeating a set of nested inputs.",
        },
      },
      {
        label: "Group",
        value: "group",
        attrs: {
          help: "Allows a grouping of inputs.",
        },
      },
      {
        label: "Toggle",
        value: "toggle",
        attrs: {
          help: "Toggle input with on/off state and customizable text.",
        },
      },
    ],
  },
];
