import { VueRenderer } from "@tiptap/vue-3";
import tippy from "tippy.js";
import ReferenceDialog from "~/components/editor/ReferenceDialog.vue";
import type { Editor } from "@tiptap/core";
import type { Reference } from "~/types";
import type { Selection } from "prosemirror-state";
import type { EditorView } from "@tiptap/pm/view";
import type { ReferenceStorage } from "./references";
import type { FormKitNode } from "@formkit/core";
import { components } from "~/prompts/inputs";

export function items(
  node: FormKitNode | undefined,
  { query }: { query: string | null }
): Reference[] {
  if (!node) {
    return [];
  }
  const siblings = node.parent?.children ?? [];
  return siblings
    .filter(
      (sibling) =>
        sibling !== node &&
        !components.includes(sibling.props.type as (typeof components)[number])
    )
    .map(
      (sibling): Reference => ({
        label: sibling.props.label || `[${sibling.name}]`,
        id: `node:${sibling.name}`,
        theme: sibling.props.type,
      })
    );
}

interface OpenReferenceDialogOptions {
  editor: Editor;
  items: Array<Reference>;
  view: EditorView;
  selection: Selection;
  query?: string;
  storage: ReferenceStorage;
  command: (item: Reference) => void;
  clientRect: (() => DOMRect) | null;
}

export function openReferenceDialog(options: OpenReferenceDialogOptions) {
  const { editor, items: suggestionItems, command, clientRect } = options;
  const storage = editor.storage.reference || {};

  if (typeof storage?.referenceDialog?.popup?.setProps === "function") {
    // Update existing dialog
    storage.referenceDialog.component.updateProps({
      items: suggestionItems,
      command,
    });
    // Reposition the popup
    storage.referenceDialog.popup.setProps({
      getReferenceClientRect: clientRect,
    });

    return storage.referenceDialog;
  }

  // Create new dialog
  const component = new VueRenderer(ReferenceDialog, {
    props: {
      items: suggestionItems,
      command,
    },
    editor,
  });

  if (!clientRect) {
    return;
  }

  const [popup] = tippy("body", {
    getReferenceClientRect: clientRect,
    appendTo: () => document.body,
    content: component.element!,
    showOnCreate: true,
    interactive: true,
    trigger: "manual",
    placement: "bottom-start",
  });

  const referenceDialog = {
    component,
    popup,
    destroy() {
      popup.destroy();
      component.destroy();
      editor.storage.reference.referenceDialog = null;
    },
    updateProps(newProps: {
      items?: Array<Reference>;
      command?: (item: Reference) => void;
    }) {
      component.updateProps(newProps);
    },
  };

  editor.storage.reference = { referenceDialog };

  return referenceDialog;
}
