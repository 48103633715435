<script lang="ts" setup>
import { defaultConfig, FormKitIcon, FormKitProvider } from "@formkit/vue";
import { text } from "@formkit/icons";
import type { Reference } from "~/types";
import formkitConfig from "~/formkit.config";
import { availableInputs as inputGroups } from "~/utils/groupedInputs.js";
import type { FormKitOptionsItem } from "@formkit/inputs";

const props = defineProps<{
  items: Array<Reference>;
  command: (item: Reference) => void;
}>();

const activeItem = ref<Reference | null>(null);

const emit = defineEmits(["selectItem"]);

const availableInputs = inputGroups.reduce((inputs, group) => {
  group.options.forEach((option) => {
    inputs[option.value as string] = { color: group.color, ...option };
  });
  return inputs;
}, {} as Record<string, FormKitOptionsItem & { color: string }>);

const onKeyDown = ({ event }: { event: KeyboardEvent }) => {
  if (event.key === "ArrowUp") {
    upHandler();
    return true;
  }

  if (event.key === "ArrowDown") {
    downHandler();
    return true;
  }

  if (event.key === "Enter") {
    enterHandler();
    return true;
  }

  return false;
};

const upHandler = () => {
  if (activeItem.value) {
    const index = props.items.findIndex((item) => item === activeItem.value);
    if (index > 0) {
      activeItem.value = props.items[index - 1];
    } else {
      activeItem.value = props.items[props.items.length - 1];
    }
  }
};

const downHandler = () => {
  if (activeItem.value) {
    const index = props.items.findIndex((item) => item === activeItem.value);
    if (index < props.items.length - 1) {
      activeItem.value = props.items[index + 1];
    } else {
      activeItem.value = props.items[0];
    }
  } else if (props.items.length > 0) {
    activeItem.value = props.items[0];
  }
};

const enterHandler = () => {
  if (activeItem.value) {
    props.command(activeItem.value);
  }
};

defineExpose({
  onKeyDown,
});

const config = defaultConfig(formkitConfig());

const colors = {
  green: {
    bg: "bg-green-300",
    text: "text-green-700",
  },
  blue: {
    bg: "bg-blue-300",
    text: "text-blue-700",
  },
  purple: {
    bg: "bg-purple-300",
    text: "text-purple-700",
  },
  orange: {
    bg: "bg-orange-300",
    text: "text-orange-700",
  },
  red: {
    bg: "bg-red-300",
    text: "text-red-700",
  },
  teal: {
    bg: "bg-teal-300",
    text: "text-teal-700",
  },
  yellow: {
    bg: "bg-yellow-300",
    text: "text-yellow-700",
  },
  indigo: {
    bg: "bg-indigo-300",
    text: "text-indigo-700",
  },
};
function bgColor(item: Reference) {
  if (item.theme in availableInputs) {
    const color = availableInputs[item.theme].color;
    if (color in colors) {
      return colors[color as keyof typeof colors].bg;
    }
  }
  return "bg-green-200";
}
function textColor(item: Reference) {
  if (item.theme in availableInputs) {
    const color = availableInputs[item.theme].color;
    if (color in colors) {
      return colors[color as keyof typeof colors].text;
    }
  }
  return "text-green-700";
}

const handleClick = (event: MouseEvent, item: Reference) => {
  event.preventDefault();
  props.command(item);
};
</script>

<template>
  <div class="rounded-md py-1 shadow-xl bg-white border">
    <FormKitProvider :config="config">
      <div class="local-panel">
        <ul>
          <li
            v-for="item in items"
            :key="item.id"
            class="px-3 py-1 flex gap-2 justify-start last:mb-0 cursor-pointer"
            :class="{ 'bg-slate-200': item === activeItem }"
            :data-is-active="item === activeItem"
            @mouseover="activeItem = item"
            @click="handleClick($event, item)"
          >
            <div
              class="rounded-md flex items-center m-auto w-6 h-6"
              :class="bgColor(item)"
            >
              <FormKitIcon
                :icon="item.theme"
                class="item-icon w-3.5 h-3.5 flex items-center m-auto"
                :class="textColor(item)"
              />
            </div>
            <div class="grow text-sm flex items-center">
              {{ item.label }}
            </div>
          </li>
        </ul>
      </div>
    </FormKitProvider>
  </div>
</template>

<style scoped>
.item-icon :deep(svg) {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
